import { Device } from "@nuxtjs/device/dist/runtime/types";
import { Method } from "~/enums";

export const useDeviceStore = defineStore("deviceStore", {
	state: () => ({
		device: "" as string,
		deviceInfo: null as Device | null,
		deviceCheckList: ["isMobile", "isDesktop"],
    defaultDeviceInfo: null as any,
		appInfo: null as any,
		deviceWidth: null as number | null,
		deviceHeight: null as number | null,
		devicePixelRatio: null as number | null,


		dpiObject: {
			dppx: 0,
			dpi: 0,
			orientation: 0,
			screenW: 0,
			screenH: 0,
			windowW: 0,
			windowH: 0,
			viewPortX: 0,
			viewPortY: 0,
			screenX: 0,
			screenY: 0
		}
	}),

	actions: {
		setDevice() {
			const deviceInfo = useDevice();
			this.deviceInfo = deviceInfo;
      this.defaultDeviceInfo = deviceInfo;
			let trueDevices = [];
			for (const [key, value] of Object.entries(deviceInfo)) {
				if (this.deviceCheckList.includes(key) && value === true) {
					trueDevices.push(key);
				}
			}

			this.device = trueDevices[0];

			// useLocalStorage("intro", deviceInfo);

			// this.device = isMobile
			return deviceInfo;
		},
		async getAppInfo(appId: string) {
			const res = await useCustomFetch(`/api/v1/apps/${appId}`, { method: Method.GET });
			this.appInfo = res.data.value;
			return res.data.value;
		},
		async getDeviceSize(width: number, height: number, devicePixelRatio: number, dpi: number, screen: Screen){
			this.deviceWidth = width;
			this.deviceHeight = height;
			this.devicePixelRatio = height / width;

			if(devicePixelRatio !== 'undefined'){
				this.dpiObject.dppx = devicePixelRatio;
			} else {
				for(let i = 5; i >= 1; i = (i - 0.05).toFixed(2)){
					if (window.matchMedia("(-webkit-min-device-pixel-ratio: " + i + ")").matches 
							|| window.matchMedia("(min-resolution: " + i + "dppx)").matches) {
						this.dpiObject.dppx = i;
						break;
					}				
				}
			}

			this.dpiObject.dpi = dpi;
			
				if (typeof screen.orientation !== 'undefined') {
					this.dpiObject.orientation = screen.orientation.angle;
				} else {
					this.dpiObject.orientation = window.orientation;
				}

				
				this.dpiObject.screenW = Math.max(screen.width || 0);
				this.dpiObject.screenH = Math.max(screen.height || 0);
				this.dpiObject.windowW = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
				this.dpiObject.windowH = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);
		
				this.dpiObject.viewPortX = this.dpiObject.windowW * this.dpiObject.dppx;
				this.dpiObject.viewPortY = this.dpiObject.windowH * this.dpiObject.dppx;
				this.dpiObject.screenX = this.dpiObject.screenW * this.dpiObject.dppx;
				this.dpiObject.screenY = this.dpiObject.screenH * this.dpiObject.dppx;
		}
	},
});
