import { useCommonCodeStore } from "@/store/commonCodeStore";
import { useEquipStore } from "@/store/equipStore";
import { useEtcStore } from "@/store/etcStore";
import { useRouteStore } from "@/store/routeStore";
import { useSiteStore } from "@/store/siteStore";
import { useUserStore } from "@/store/userStore";
import { UserType } from '@/enums';
import Swal from "sweetalert2";
import { useDeviceStore } from "~/store/deviceStore";
import { useInstallerStore } from "~/store/installerStore";

export default defineNuxtRouteMiddleware(async (to, from) => {
	const siteStore = useSiteStore();
	const userStore = useUserStore();

	const commonCodeStore = useCommonCodeStore();
	const routeStore = useRouteStore();
	const equipStore = useEquipStore();
	const etcStore = useEtcStore();
  const deviceStore = useDeviceStore();
	const installerStore = useInstallerStore();


  deviceStore.setDevice();

  const route = useRoute();
	const router = useRouter();


  
  const deviceType = deviceStore.device; 

	const {$invokeNative} = useNuxtApp();


	// router.beforeEach(async (to, from) => {

	// return ''
	// });

	// if (to.params.id === "1") {
	// 	return abortNavigation();
	// }
	// In a real app you would probably not redirect every route to `/`
	// however it is important to check `to.path` before redirecting or you
	// might get an infinite redirect loop
	const isAutoLoginCheck = ref(window.localStorage.getItem("autoLogin") ? window.localStorage.getItem("autoLogin") : 'false');

  const userToken = useCookie("userToken");

	// alert("intro auto login")
	// alert(userToken.value);

  if (userToken.value === undefined) {
		isAutoLoginCheck.value = "false";
	}

  // if(isAutoLoginCheck.value === 'true'){
  //   if(to.fullPath === "/mobile/user/login"){
  //     return navigateTo("/");
  //   }
  // }



  if(deviceType === 'isDesktop'){
    if(to.fullPath === '/'){
      setPageLayout("toc-non-side");
      return navigateTo('/toc/user/login');
    } else if(to.fullPath === '/mobile/user/login'){
      if(isAutoLoginCheck.value === 'true'){
        return navigateTo('/mobile');
      }
    } else if(to.fullPath === '/mobile'){
      if(isAutoLoginCheck.value === 'true'){
        let result = await userStore.refreshToken();
        login(result?.data.value?.userSeq as number);
      }
    }
  } else if(deviceType === 'isMobile'){
    if(to.fullPath === '/mobile/user/login'){
      if(isAutoLoginCheck.value === 'true'){
        return navigateTo('/');
      }
    } else if(to.fullPath === '/'){
			console.log(isAutoLoginCheck.value);
      if(isAutoLoginCheck.value === 'true'){
        let result = await userStore.refreshToken();
        login(result?.data.value?.userSeq as number);
      } else {
				return navigateTo('/mobile');
			}
    }
  }
  

  // if(to.fullPath === "/"){
  //   setPageLayout("non-header-footer");

  //   if(deviceType === 'isDesktop'){
  //     // console.log('::: to toc ::::');

  //     setPageLayout("toc-non-side");
  //     return navigateTo('/toc/user/login');
  //   } else if(deviceType === 'isMobile'){
  //     if(isAutoLoginCheck.value === 'true'){
  //       let result = await userStore.refreshToken();
  //       login(result?.data.value?.userSeq as number);
  //     } else {
  //       return navigateTo('/mobile/user/login')
  //     }
  //   }
  // }

  // if(to.fullPath === '/mobile/user/login'){
  //   if(isAutoLoginCheck.value === 'true'){
  //     return navigateTo('/');
  //   }
  // }



	// if (from.fullPath === '/') {
	// 	setPageLayout("non-header-footer");
  //   console.log(isAutoLoginCheck.value)
  //   // if(deviceType === 'isMobile'){
  //     if (isAutoLoginCheck.value === "true") {
  //       let result = await userStore.refreshToken();
  //       console.log(result);
  //       login(result?.data.value?.userSeq as number);
  //       return;
  //     } else {
  //       if(deviceType === 'isMobile'){
  //         return navigateTo("/mobile/user/login");
  //       }
  //     }
  //   // }
	// } 

  // if(to.fullPath.includes('/mobile/user/login') && isAutoLoginCheck.value === "true"){
  //   setPageLayout("non-header-footer");

  //   let result = await userStore.refreshToken();
  //   console.log(result);
  //   login(result?.data.value?.userSeq as number);
  // }

	async function login(userSeq: number) {
    await commonCodeStore.getInitAllCodeMap();

		installerStore.init();

		try {
			if (userSeq) {
				/** 코드 카테고리  */
				await commonCodeStore.getCodeCategories();
				/** 모든 코드 리스트 */
				await routeStore.makeRoutes();
				await commonCodeStore.getCities();
				// await userStore.getUsers(1, 1000, 2, "owner");
				if (userStore.getUserType === UserType.Manager || userStore.getUserType === UserType.Admin) {
					await equipStore.initEquipList();
					// let mapInfo = await equipStore.getEquipMapData(userStore.loginUserInfo?.userSeq as number);
					etcStore.setFirstMap(true);
					etcStore.setFirstEquipList(true);
					router.replace(`/mobile/user/${userSeq}/equipMap`);
				} else if(userStore.getUserType === UserType.Worker){
					
					if(deviceStore.device === 'isMobile'){
						//@ts-ignore
						const gpsInfo = await $invokeNative.getLocation(false);
						console.log(JSON.stringify(gpsInfo));
						if(gpsInfo.isSuccess === true){
							installerStore.setWorkerGps(gpsInfo.data.latitude, gpsInfo.data.longitude);
						} 
						
						// else if(gpsInfo.errorCode === '-1'){
						
						// 	Swal.fire({
						// 		html: `<p style="font-size: 15px;">기기의 GPS 기능이 비활성화 상태입니다.<br>GPS 기능을 활성화해주세요.</p>`,
						// 		confirmButtonColor: "#1949e9",
						// 		confirmButtonText: "확인",
						// 		width: "85%",
						// 	});
						// } else if(gpsInfo.errorCode === '0'){
						// 	Swal.fire({
						// 		html: `<p style="font-size: 15px;">GPS의 권한을 획득하지 못하였습니다.<br>정확한 작업을 위해 GPS 권한허용을 승인해주세요.</p>`,
						// 		confirmButtonColor: "#1949e9",
						// 		confirmButtonText: "확인",
						// 		width: "85%",
						// 	});
						// }
					}
					router.replace('/mobile/installer/policy/all');

				} else {
					await siteStore.getSiteList();

					await equipStore.initEquipList();
					await equipStore.setSelectSite(equipStore.convertSiteList[0].siteName);

					etcStore.setOwnerFirstStatus(true);

					router.replace(`/mobile/user/${userSeq}/views/status`);

				}
			} else {
				router.replace("/mobile/none-contents-page");
			}
		} catch (e) {
			alert(e);

			Swal.fire({
				html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
				confirmButtonColor: "#1949e9",
				confirmButtonText: "확인",
				width: "85%",
			});
		}
	}
	
	// async function login(userSeq: number) {
	// 	try {
	// 		if (userSeq) {
	// 			/** 코드 카테고리  */
	// 			await commonCodeStore.getCodeCategories();
	// 			/** 모든 코드 리스트 */
	// 			await commonCodeStore.getInitAllCode();
	// 			await routeStore.makeRoutes();
	// 			await commonCodeStore.getCities();
	// 			// await userStore.getUsers();

	// 			if (userStore.getUserType === UserType.Manager || userStore.getUserType === UserType.Admin) {
	// 				let result = await equipStore.getEquipList(userSeq);
	// 				// let mapInfo = await equipStore.getEquipMapData(userStore.loginUserInfo?.userSeq as number);
	// 				etcStore.setFirstMap(true);
	// 				etcStore.setFirstEquipList(true);
	// 				// let routeName = commonCodeStore.getEngSourceName(equipStore.selectEquipInfo.engSource as string);

	// 				router.replace(`/mobile/user/${userSeq}/equipMap`);
	// 			} else {
	// 				const siteResult = await siteStore.getSiteList(userSeq);

	// 				await siteStore.selectSite(siteResult[0], userSeq);
	// 				await equipStore.getEquipList(userSeq);
	// 				// await equipStore.set
	// 				await equipStore.setSelectSite(userSeq, equipStore.convertSiteList[0].siteName);

	// 				// etcStore.setFirstMap(false);
	// 				// etcStore.setFirstEquipList(false);
	// 				etcStore.setOwnerFirstStatus(true);

	// 				router.replace(`/mobile/user/${userSeq}/views/status`);
	// 			}
	// 		} else {
	// 			router.replace("/mobile/none-contents-page");
	// 		}
	// 	} catch (e) {

	// 		// alert();
	// 		Swal.fire({
	// 			html: `<p style="font-size: 15px;">오류가 발생하였습니다.<br>잠시 후 다시 시도해주세요.</p>`,
	// 			confirmButtonColor: "#1949e9",
	// 			confirmButtonText: "확인",
	// 			width: "85%",
	// 		})
	// 	}
	// }
});

