import { NativeRequest, NativeResponse, UserDeviceInfo } from "@/models/dto-types";
import CommonUtils from "@/utils/common-utils";
import { useUserStore } from "@/store/userStore";
import { NativeAction, PermissionStatus } from "@/enums";
import { useDeviceStore } from "~/store/deviceStore";

export default defineNuxtPlugin((nuxtApp) => {

  const userStore = useUserStore();
  const deviceStore = useDeviceStore();

  //생체 인증 설정을 위한 글로벌 함수 (App -> web)
  // @ts-ignore 
  window.changedBioLock = (isBioLock: boolean) => {

    //스토어에 생체 인증 설정 상태를 업데이트 한다.
    userStore.setBioLock(isBioLock);
  };

  // @ts-ignore 
  window.updatePermission = (permissionType: PermissionType, permissionStatus: PermissionStatus) => {

    userStore.setPermission(permissionType, permissionStatus);
    alert(`permission => ${permissionType}, isDenied => ${permissionStatus}`);    
  }

  // @ts-ignore 
  window.logout = async () => {

    alert('Logout');
    //스토어에 생체 인증 설정 상태를 업데이트 한다.
    await userStore.logout();
  };

  // @ts-ignore 
  window.logout2 = async () => {

    alert('Logout2');
  };

  return {
    provide: {
      invokeNative: {
        getDeviceInfo(): Promise<(NativeResponse | null)> {

          return new Promise((resolve, reject) => {

            if (!deviceStore.deviceInfo) {
              resolve(null);
            }

            let nativeResponse: NativeResponse | null = null;

            if (deviceStore.deviceInfo!.isAndroid) {
              try{
                const response = jsBridge?.getDeviceInfo();
                nativeResponse = JSON.parse(response);
                resolve(nativeResponse);
              } catch(e){
                const message = e.message;

                if(message === 'jsBridge is not defined'){
                  alert('디바이스 간 통신에 오류가 발생하였습니다. 관리자에게 문의해주세요.');
                }
              }
            
            } else if (deviceStore.deviceInfo!.isIos) {

              try{
                const callbackName = 'callback_' + Math.random().toString(36).substring(2, 9);              

                window[callbackName] = function(result: string) {
  
                  try {
                    const r: NativeResponse = JSON.parse(result);
                    resolve(r);
                  } catch (err){
                    reject(err);
                  } finally {
  
                      // callback 함수 사용 후 제거
                      delete window[callbackName];
                  }
                }
  
                const request: NativeRequest = {
                  action: "getDeviceInfo",
                  callbackName: callbackName,
                }
                
                window.webkit.messageHandlers.jsBridge.postMessage(request);
              } catch(e){
                const message = e.message;

                alert(message);
              }
           

            } else {
              resolve(null);
            }
          });
        },     

        setBio(isEnable: boolean) {
          jsBridge?.setBio(isEnable);

          //setBio 이후 setBio를 호출한 곳 아래 코드를 수행해야함.
          // window.localStorage.setItem('isBioLock', isEnable.toString());
          // userStore.setBioLock(isEnable);
        },
        
        getLocation(isLastknown: boolean): Promise<(NativeResponse | null)> {

          //파라미터 => false: 현재위치 가져오기, true: 마지막으로 알려진 위치 가져오기
          // const response = jsBridge?.getLocation(isLastknown);

          // const nativeResponse: NativeResponse = JSON.parse(response);
          // return nativeResponse;

          return new Promise((resolve, reject) => {

            if (!deviceStore.deviceInfo) {
              resolve(null);
            }

            let nativeResponse: NativeResponse | null = null;

            if (deviceStore.deviceInfo!.isAndroid) {
              const response = jsBridge?.getLocation(isLastknown);
              nativeResponse = JSON.parse(response);
              resolve(nativeResponse);
            } else if (deviceStore.deviceInfo!.isIos) {

              const callbackName = 'callback_' + Math.random().toString(36).substring(2, 9);

              window[callbackName] = function (result: string) {

                try {
                  const r: NativeResponse = JSON.parse(result);
                  resolve(r);
                } catch (err) {
                  reject(err);
                } finally {

                  // callback 함수 사용 후 제거
                  delete window[callbackName];
                }
              }

              const request: NativeRequest = {
                action: "getLocation",
                callbackName: callbackName,
                callParams: [String(isLastknown)],
              }

              window.webkit.messageHandlers.jsBridge.postMessage(request);

            } else {
              resolve(null);
            }
          });
        },

        openImages(uri: string) : Promise<(NativeResponse | null)> {
          return new Promise((resolve, reject) => {

            if (!deviceStore.deviceInfo) {
              resolve(null);
            }

            let nativeResponse: NativeResponse | null = null;

            if (deviceStore.deviceInfo!.isAndroid) {
              const response = jsBridge?.openImages(uri);
              nativeResponse = JSON.parse(response);
              resolve(nativeResponse);
            } else if (deviceStore.deviceInfo!.isIos) {

              const callbackName = 'callback_' + Math.random().toString(36).substring(2, 9);

              window[callbackName] = function (result: string) {

                try {
                  const r: NativeResponse = JSON.parse(result);
                  resolve(r);
                } catch (err) {
                  reject(err);
                } finally {

                  // callback 함수 사용 후 제거
                  delete window[callbackName];
                }
              }

              const request: NativeRequest = {
                action: "openImages",
                callbackName: callbackName,
                callParams: [String(uri)],
              }

              window.webkit.messageHandlers.jsBridge.postMessage(request);

            } else {
              resolve(null);
            }
          });
        },
        
        setIntro(isShow:boolean) : Promise<(NativeResponse | null)> {
          return new Promise((resolve, reject) => {

            if (!deviceStore.deviceInfo) {
              resolve(null);
            }

            let nativeResponse: NativeResponse | null = null;

            if (deviceStore.deviceInfo!.isAndroid) {
              const response = jsBridge?.setIntro(isShow);
              nativeResponse = JSON.parse(response);
              resolve(nativeResponse);
            } else if (deviceStore.deviceInfo!.isIos) {

              const callbackName = 'callback_' + Math.random().toString(36).substring(2, 9);

              window[callbackName] = function (result: string) {

                try {
                  const r: NativeResponse = JSON.parse(result);
                  resolve(r);
                } catch (err) {
                  reject(err);
                } finally {

                  // callback 함수 사용 후 제거
                  delete window[callbackName];
                }
              }

              const request: NativeRequest = {
                action: "setIntro",
                callbackName: callbackName,
                callParams: [Boolean(isShow)],
              }

              window.webkit.messageHandlers.jsBridge.postMessage(request);

            } else {
              resolve(null);
            }
          });
        },

        openAppSetting() {

          if (!deviceStore.deviceInfo)
            return;

          if (deviceStore.deviceInfo.isAndroid) {
            jsBridge?.openAppSetting();
          } else {

            const request: NativeRequest = {
              action: "openAppSetting",
            }

            window.webkit.messageHandlers.jsBridge.postMessage(request);
          }
        },
        DontSeeIntro(){
          jsBrige?.DontSeeIntro();
        },

        haptic(isLong = false) {

          if (!deviceStore.deviceInfo)
            return;

          if (deviceStore.deviceInfo.isAndroid) {
            jsBridge?.haptic(isLong);
          } else if (deviceStore.deviceInfo.isIos) {

            const request: NativeRequest = {
              action: "haptic",
              callParams: [`${isLong}`],
            }

            window.webkit.messageHandlers.jsBridge.postMessage(request);
          }
        },

        uploadPdf(fileName: string, data: string): Promise<(NativeResponse | null)> {
          return new Promise((resolve, reject) => {

            if (!deviceStore.deviceInfo) {
              resolve(null);
            }

            let nativeResponse: NativeResponse | null = null;

            if (deviceStore.deviceInfo!.isAndroid) {
              const response = jsBridge?.uploadPdf(fileName, data);
              nativeResponse = JSON.parse(response);
              resolve(nativeResponse);
            } else if (deviceStore.deviceInfo!.isIos) {

              const callbackName = 'callback_' + Math.random().toString(36).substring(2, 9);

              window[callbackName] = function (result: string) {

                try {
                  const r: NativeResponse = JSON.parse(result);
                  resolve(r);
                } catch (err) {
                  reject(err);
                } finally {

                  // callback 함수 사용 후 제거
                  delete window[callbackName];
                }
              }

              const request: NativeRequest = {
                action: "uploadPdf",
                callbackName: callbackName,
                callParams: [String(fileName), String(data)],
              }

             window.webkit.messageHandlers.jsBridge.postMessage(request);
   
            } else {
              resolve(null);
            }
          });
          // const response = jsBridge?.uploadPdf(fileName, data);
          // const nativeResponse: NativeResponse = JSON.parse(response);
          // return nativeResponse;
        },

        sharePdf():
         Promise<(NativeResponse | null)> {
          return new Promise((resolve, reject) => {

            if (!deviceStore.deviceInfo) {
              resolve(null);
            }

            let nativeResponse: NativeResponse | null = null;

            if (deviceStore.deviceInfo!.isAndroid) {
              const response = jsBridge?.sharePdf();
              nativeResponse = JSON.parse(response);
              resolve(nativeResponse);
            } else if (deviceStore.deviceInfo!.isIos) {

              const callbackName = 'callback_' + Math.random().toString(36).substring(2, 9);

              window[callbackName] = function (result: string) {

                try {
                  const r: NativeResponse = JSON.parse(result);
                  resolve(r);
                } catch (err) {
                  reject(err);
                } finally {

                  // callback 함수 사용 후 제거
                  delete window[callbackName];
                }
              }

              const request: NativeRequest = {
                action: "sharePdf",
                callbackName: callbackName,
                callParams: [],
              }

              let result = window.webkit.messageHandlers.jsBridge.postMessage(request);
              resolve(result);
            } else {
              resolve(null);
            }
          });
          // jsBridge?.sharePdf();
        },

        dev() : Promise<(NativeResponse | null)> {
          return new Promise((resolve, reject) => {

            if (!deviceStore.deviceInfo) {
              resolve(null);
            }

            let nativeResponse: NativeResponse | null = null;

            if (deviceStore.deviceInfo!.isAndroid) {
              const response = jsBridge?.dev();
              nativeResponse = JSON.parse(response);
              resolve(nativeResponse);
            } else if (deviceStore.deviceInfo!.isIos) {

              const callbackName = 'callback_' + Math.random().toString(36).substring(2, 9);

              window[callbackName] = function (result: string) {

                try {
                  const r: NativeResponse = JSON.parse(result);
                  resolve(r);
                } catch (err) {
                  reject(err);
                } finally {

                  // callback 함수 사용 후 제거
                  delete window[callbackName];
                }
              }

              const request: NativeRequest = {
                action: "dev",
                callbackName: callbackName,
              }

              window.webkit.messageHandlers.jsBridge.postMessage(request);

            } else {
              resolve(null);
            }
          });

          // const response = jsBridge?.dev();
          // const nativeResponse: NativeResponse = JSON.parse(response);
          
          // return nativeResponse;
        },

        fileInput(){
          const response = jsBridge?.fileInput();
          const nativeResponse: NativeResponse = JSON.parse(response);
          
          return nativeResponse;
        }
      },  
      // //TODO: 추후 리팩토링시 App의 CustomWebView 수정하여 안드로이드와 iOS 호출 방식 일원화 필요.	
      invokeNativeAction: (action: NativeAction) => {

        try {
          // @ts-ignore
          jsBridge?.invokeAction(action);
          
        } catch {
          // @ts-ignore
          //invokeAction(action);
          window.webkit.messageHandlers.invokeAction.postMessage(action);
        }
      },

      invokeNativeDownloadAction:(fileName: string, data: string) => {

        try {
          // @ts-ignore
          jsBridge?.downloadAction(fileName, data);
        } catch {
          // @ts-ignore
          //downloadAction({ fileName: fileName, data: data });
          window.webkit.messageHandlers.downloadAction.postMessage({ fileName: fileName, data: data });
        }
      }
    }
  }
});