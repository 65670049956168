export const appPageTransition = false

export const appLayoutTransition = false

export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=yes"}],"link":[{"href":"//cdn.jsdelivr.net/gh/wan2land/d2coding/d2coding-full.css","rel":"stylesheet"},{"href":"https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@2.0/nanumsquare.css","rel":"stylesheet","type":"text/css"}],"style":[],"script":[{"src":"//dapi.kakao.com/v2/maps/sdk.js?autoload=false&libraries=clusterer,services&appkey=ba55af385253fd00d4cf5a09e0beb09f"},{"src":"//t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js"}],"noscript":[],"charset":"utf-8"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"